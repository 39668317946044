import { Helmet } from "react-helmet";
import "./SpentBleechingEarth.css";
import SpentBleechingEarthFaq from "./SpentBleechingEarthFaq";
import vid from "../components/images/Spent Bleaching Earth-3.webm";
import vidIos from "../components/images/Spent Bleaching Earth-3.mp4";
/* import Contact from "./Contact"; */
import ReactPlayer from "react-player";

const SpentBleechingEarth = () => {
  return (
    <>
      <Helmet>
        <title>Spent Bleeching Earth, Spent Bleeching Earth plant </title>
        <meta
          name="description"
          content="Spent bleeching Earth is a solid waste material generated as part of refining process in a vegetable oil industry worldwide."
        />
        <meta
          name="keywords"
          content=" Spent bleeching Earth, Spent Bleeching Earth plant, what is spent bleaching, spent bleaching, bleaching earth, spent bleaching earth extraction "
        />
      </Helmet>
      <div>
        <h1 className="Refinery">
          CONTINUE SPENT BLEACHING EARTH EXTRACTION PLANT
        </h1>
      </div>
      <div className="refinaryContainer">
        <div className="HimgContainer">
          <img
            className="MesoCarpImg"
            src="./Images/5 5.png"
            alt="cotton -Header-Img"
          />
        </div>
        <h1 className="pageHeading2">
          Continues Spent Bleaching Earth Extraction Plant{" "}
        </h1>
        <h5 className="mecpro1">
          Spent bleeching Earth is a solid waste material generated as part of
          refining process in a vegetable oil industry worldwide.
        </h5>
        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/spentbleechingearth.png"
            alt="spent bleaching-1"
          />
          <article>
            Pre-treatment of crude oil in an edible oil refinery involves
            degumming and bleaching, which generates significant quantities of
            spent Bleaching Earth. The spent bleaching earth is a by-product,
            which contains a high percentage of leftover oil. A large quantity
            of spent bleaching earth is disposed of in landfills, which is a
            source of fire and environmental hazards due to the substantial oil
            content. <br />
            <br />
            Mecpro has vast experience in the refining and has developed
            technology to extract the oil from Spent Bleaching Earth in a
            continuous process without making pellets, using both Flammable and
            Non- Flammable solvents, as against the batch process prevalent in
            the industry currently.
          </article>
        </section>
        <section className="spentImages">
          <img
            className="spentImages"
            src="./Images/untitled3-4@2x.png"
            alt="spent bleaching-1"
          />
          <div className="spentImages2">
            <img
              className="Spentb"
              src="./Images/3-12@2x.png"
              alt="spent bleaching-2"
            />
            <img
              className="Spentb"
              src="./Images/2-181016@2x.png"
              alt="spent bleaching-3"
            />
            <img
              className="Spentb"
              src="./Images/1-1801777412@2x.png"
              alt="spent bleaching-4"
            />
          </div>
        </section>
        <h5 className="mecpro1">
          The oil recovered in a close circuit even can use in the edible oil
          stream, however, once it is open to the atmosphere can we used as a
          row material for oleo chemical industry, biodiesel, soap and soap
          noodles.
        </h5>{" "}
        <br />
        <section className="refVid">
                <ReactPlayer
                                  url="https://www.youtube.com/watch?v=x22W15fjr8o" // Your YouTube video URL
                                  controls={true} // Show default controls
                                  width="100%"
                                  height="100%"
                                  className="ytplayer"
                                /> 
        </section>{" "}
        <br />
        <section className="refinery1">
          <article>
            {" "}
            Assuming an average oil retention volume of 20% for the bleaching
            earth normally used, approximately 600,000 Mt of oil is lost in the
            bleaching process constituting a significant economic loss.
            Prevention of this loss would be of immense importance in view of
            the huge deficit between supply and demand of vegetable oils in the
            global market. Serious fire and pollution hazards arising during
            stocking and disposal of spent earth also call for appropriate
            technical solutions for recovery of oil from spent earth. In view of
            these, Mecpro developed a method to extract the oil from spent
            bleaching earth, economically and safely.
          </article>
          <img
            className="refImg"
            src="./Images/untitled1-44@2x.png"
            alt="spent bleaching-5"
          />
        </section>
        <h5 className="mecpro1">
          The first continuous plant in the world with non-flammable solvent was
          established with a processing capacity of 150 MT per day and a number
          of batch solvent extraction plants with normal solvent were
          established. Besides, the importance of oil recovery, treatment of
          bleaching clays is also important for safety and security reasons,
          because fatty clays are prone to catching fire.
        </h5>
        <h5 className="mecpro1">
          The environmental aspect, with the hazard of pollution of ground
          water, because of the draining of fat by rain and causing.
        </h5>
        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/4-7@2x.png"
            alt="spent bleaching-6"
          />
          <h5 className="ref1h5">
            <h3 className="pageHeading"> Technical Experise </h3>
            We believe in rendering the most practical Buiseness solutions to
            our client
          </h5>
        </section>
        <h5 className="mecpro1">
          The new technology would thus transform that was hitherto waste into a
          valuable material and provide a more environmentally friendly method
          of dealing with spent earth disposal. Besides, extracting oil in an
          affordable manner will help will help to enhance the profitability of
          the plant. The preparatory less new process will reduce 40% of the
          power consumption, enhance oil quality, better safety in process, less
          manpower and utility consumption.
        </h5>
        <div className="spentLast">
          <img src="./Images/CIMG0461.jpg" alt="spent bleaching-7" />
          <img src="./Images/CIMG0459.jpg" alt="spent bleaching-8" />
          <img src="./Images/CIMG0452.jpg" alt="spent bleaching-9" />
        </div>
        <h5 className="mecpro1">
          The new technology would thus transform that was hitherto waste into a
          valuable material and provide a more environmentally friendly method
          of dealing with spent earth disposal. Besides, extracting oil in an
          affordable manner will help will help to enhance the profitability of
          the plant. The preparatory less new process will reduce 40% of the
          power consumption, enhance oil quality, better safety in process, less
          manpower and utility consumption.
        </h5>
        <br />
        <br />
      </div>
      <h1 className="faqHeading">Frequently Asked Questions!</h1>
      <div className="faqContainer">
        <SpentBleechingEarthFaq />
        <div className="faqImages">
          <img src="./Images/CIMG0485.JPG" alt="spent bleaching-img" />
          <img src="./Images/CIMG0484.JPG" alt="spent bleaching-img1" />
          <img src="./Images/CIMG0456.JPG" alt="spent bleaching-img2" />
        </div>
      </div>
    </>
  );
};

export default SpentBleechingEarth;
