import React, { useRef, useEffect, useState } from 'react';


const VideoComponent = () => {
  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // Pause the video when it's out of the viewport
      const video = videoRef.current;
      if (isVideoPlaying) {
        const rect = video.getBoundingClientRect();
        const isVideoInView = rect.top < window.innerHeight && rect.bottom >= 0;
        setIsVideoPlaying(isVideoInView);
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <video ref={videoRef} autoPlay={isVideoPlaying}>

        <source src="./components/Assets/few-employee.webm" type="video/webm" />

      </video>
    </div>


  );
};

export default VideoComponent;
