import "./Refinery.css";
import "./Fractionation.css";
import FractionationFaq from "./FractionationFaq";
import { Helmet } from "react-helmet";
import frac from "../components/Assets/FRACTIONATION.webm";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
/* import { Parallax } from "react-parallax" */

const Fractionation = () => {
  return (
    <>
      <Helmet>
        <title>Fractionation</title>
        <meta
          name="description"
          content="Mecpro online crystallization and fractionation
                                                is a new concept that has been patented, and
                                                it is the advanced fractionation technology for
                                                fat modification for various applications. The flow of oil is continuous through the
                                                crystallizers and the crystals are formed
                                                during the flow stage itself. "
        />
        <meta
          name="keywords"
          content=" Fractionation, crystallization, crystallization and fractionation, advanced fractionation technology, 
                                                fat modification "
        />
      </Helmet>
      <div>
        <h1 className="Refinery">FRACTIONATION</h1>
      </div>
      <div className="refinaryContainer">
        <div className="HimgContainer">
          <img
            className="RefinieryHeaderImage"
            src="./Images/head FRACTIONATION.webp"
            alt="Refinary-Header-Img"
          />
        </div>
        {/*  <Parallax blur={0} bgImage=".\Images\head FRACTIONATION.webp" strength={600}>
                                        <div className="HimgContainer">

                                        </div> */}
        {/* <h1 className="pageHeading">Fractionation</h1> */}
        {/* </Parallax> */}
        <section className="refinery1">
          <img className="refImg" src="./Images/2.webp" alt="Refinery-img1" />
          <article>
            In edible oil processing, the fractionation process consists of
            controlled cooling of the oil, thereby inducing partial or
            fractional crystallization. The remaining liquid (Olein) is then
            separated from the solid fraction (Stearin) by means of filtration.
          </article>
        </section>
        <h5 className="mecpro1">
          This kind of fractionation process has been prevalent for over 150
          years in the industry. However, recently Mecpro has been able to
          successfully develop, patent and commercialize a continuous
          fractionation technology. This section is connected to the refining
          plant for continuous flow crystallization with reduced entrainment of
          olein into the crystal, creating a surface area with sandy crystals,
          which is versatile and an economical breakthrough in the industry.
        </h5>
        <section className="refVid">
          <img className="fracbigImg" src="./Images/3.webp" alt="img3" />
        </section>
        <h5 className="mecpro1">
          This process allows us to obtain oils with multiple cloud points in
          the same single flow by using different filters – so 6oC, 8oC or 10oC
          or any other cloud point oil can be obtained in the same oil flow. The
          new technology will enhance the yield of olein by 1.5 – 12% depending
          upon the cloud point targeted.
        </h5>
        <section className="refVid">
          <ReactPlayer
                           url="https://www.youtube.com/watch?v=t0AkWvA56WY" // Your YouTube video URL
                           controls={true} // Show default controls
                           width="100%"
                           height="100%"
                           className="ytplayer"
                         /> 
        </section>
        <h5 className="mecpro1">
          The process reduces the steam consumption by 95%, chilled water
          consumption by 40%, power consumption by 30%, manpower needs by 30%,
          besides better clarify and a constant level of cloud point
          continuously. Fractionation up to cloud point of 6 CP will not reduce
          the production capacity by more than 10%, as compared to 50% in the
          prevailing technology. The Olein yield is guaranteed to be higher than
          in the existing process.
        </h5>
        <section className="refVid">
          <img
            className="fracbigImg"
            src="./Images/4.webp"
            alt="Refinery-img1"
          />
        </section>
        <section className="refinery1">
          <article>
            In its quest for economization and process optimization, Mecpro has
            been able to develop process technology which can contribute to
            maximizing capacity and minimizing costs for a continuous
            fractionation plant.{" "}
          </article>
          <div className="reffImg2">
            <img
              className="reffImg2"
              src="./Images/6.png"
              alt="Refinery-img1"
            />
          </div>
        </section>
        <h5 className="mecpro1">
          It is a physical process, purely natural, that generates new fractions
          of greater value than the original oil without any loss or pollution
          and is yet very cost effective, compared to other technologies
          prevalent in the industry across the globe.
        </h5>
        <h1 className="pageHeading2">Continuos two step Fractionation</h1>
        <section className="refVid">
          <img
            className="fracbigImg"
            src="./Images/7.webp"
            alt="Refinery-img1"
          />
        </section>
        <br />
        <br />
        <h5 className="mecpro1">
          A unique technique of gravitational membrane filtration is adopted in
          palm oil fraction- ation that helps in better olein recovery on a
          sustained basis. Besides, a higher recovery by 2% to 12% is possible
          depending upon the cloud point from 15 to 65.
        </h5>
        <section className="ref2">
          <h5>
            The process of fractionation can be applied to <br />
            • Cotton seed oil <br />
            • Shea butterbr <br />
            • Sal fat <br />
            • Mango kernel oil <br />
            • Palm oil <br />
            • Rice bran oil <br />
            • Palm kernel <br />
            • Sunflower oil <br />
          </h5>
          <div className="reffImg2">
            <img src="./Images/8.png" alt="RefImg2" />
          </div>
        </section>
        <h1 className="pageHeading2">Palm Oil Fractionation Plant</h1>
        <h5 className="mecpro1">
          The palm oil fractionation process is designed to separate palm oil
          into two fractions, Olein and Stearin without the addition of any
          chemical or solvent. Mecpro fractionation plants can be counted upon
          to produce stable and filterable crystals giving you a product that
          fits your standard and quality requirements. <br />
          Mecpro crystallizers used for fractionation have a cooling coil with a
          unique design that provides a large cooling surface area. It helps in
          the formation of better crystal nuclei on which crystals are grown.
          The slow agitation and agitator configuration keeps the oil mass
          moving without stagnation even when close to cooling. The crystals
          developed during the process are separated out by using a membrane
          filter press. This allows the squeezing out of the stearin cake to get
          as much liquid olein as possible. <br /> <br />
          Refinery attached with Fractionation process reduces the steam
          consumption by 95%, chilled water consumption by 40%, power
          consumption by 30%, manpower required by 30%, besides, better clarity
          and constant level of cloud point is obtained continuously.
          Fractionation up to cloud point 6 CP will not reduce the production
          capacity beyond 10%, as compared to 50% in the prevailing technology.{" "}
          <br /> <br />
          Guaranteed higher Olein yield than existing process. In its quest for
          economization and process optimization, MECPRO has been able to
          develop process technology which can contribute to maximizing capacity
          and minimizing costs for a continuous fractionation plant.
        </h5>{" "}
        <br />
        <h5 className="mecpro1">
          <Link to="https://www.mecpro.com/blog-post/669b6dde67142a3659765afc">
            {" "}
            _Key Features and Benefits of Mecpro’s Continuous Fractionation----{" "}
          </Link>
        </h5>
      </div>{" "}
      <br />
      <br />
      <FractionationFaq />
    </>
  );
};

export default Fractionation;
