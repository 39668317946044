/* import Container from 'react-bootstrap/Container'; */
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link /* NavLink */ } from "react-router-dom";
import "./BootNav.css";
import { useEffect, useState } from "react";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import Brochure from "../components/Assets/BROCHURE.pdf";

const BasicExample = () => {
  const [Theme, setTheme] = useState("dark-theme");
  const toggleTheme = () => {
    if (Theme === "dark-theme") {
      setTheme("light-theme");
    } else {
      setTheme("dark-theme");
    }
  };

  useEffect(() => {
    document.body.className = Theme;
  }, [Theme]);

  return (
    <router>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary w-100 fixed-top "
      >
        <div className="container ">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav " /* data-bs-toggle="dropdown" data-bs-auto-close="inside" aria-expanded="true"  */
          />
          <Navbar.Collapse
            /* className='justify-content-between w-100'  */ id="basic-navbar-nav "
          >
            <Nav className="fw-bold fs-5  d-flex gap-4 ">
              <Nav.Link eventKey="1" as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link eventKey="2" as={Link} to="/AboutUs">
                About Us
              </Nav.Link>
              <Nav.Link eventKey="3" as={Link} to="/Media">
                Knowledge Hub
              </Nav.Link>

              {/*   <NavDropdown title="Technology & Research" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1"></NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/TechnologyResearchEdibleO">
                  Edible Oil Research Details
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Foodprocessingresearch">
                  Food Processing Research Details
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Greenenergyresearch">
                  Green Energy Research Details
                </NavDropdown.Item>

              </NavDropdown> */}

              <NavDropdown
                title="Project & Industry"
                id="basic-nav-dropdown"
                data-bs-toggle="dropdown"
              >
                {/*  <NavDropdown.Item href="#action/3.1">{</NavDropdown.Item>
                <NavDropdown title="Edible Oil" id="basic-nav-dropdown" data-bs-toggle="dropdown" className='dropend'>} */}

                <NavDropdown
                  title="Solvent Extraction"
                  id="basic-nav-dropdown"
                  className="dropend"
                >
                  <NavDropdown.Item href="#action/3.2"></NavDropdown.Item>

                  {/*  <NavDropdown title="Solvent Extraction Process" id="basic-nav-dropdown" className='dropend' eventKey="4.1" as={Link} to="/SolventExtraction"> */}
                  {/* <NavDropdown.Item eventKey="4.1" as={Link} to="/SolventExtraction">
                      Solvent Process Description
                    </NavDropdown.Item> */}
                  <NavDropdown.Item
                    eventKey="4.1"
                    as={Link}
                    to="/SolventExtraction"
                  >
                    Solvent Extraction Process
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey="4.2"
                    as={Link}
                    to="/ExtractionSection"
                  >
                    Extraction Section
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey="4.3"
                    as={Link}
                    to="/DesolventizationSection"
                  >
                    Desolventization Section (Patented)
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey="4.4"
                    as={Link}
                    to="/DistillationSection"
                  >
                    Distillation Section (Patented)
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey="4.5"
                    as={Link}
                    to="/VentAirPurificationSystem"
                  >
                    Vent Air Purification System (Patented)
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey="4.6"
                    as={Link}
                    to="/Lowtempmiscellaseperation"
                  >
                    Low-temperature Miscella Separation (Patented)
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey="4.7"
                    as={Link}
                    to="/MiscellaSelfwash"
                  >
                    Miscella self wash filteration (Patented)
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey="4.8"
                    as={Link}
                    to="/Palmfibreoilectraction"
                  >
                    Palm fibre oil extraction
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey="4.9"
                    as={Link}
                    to="/PalmKerneloilextraction"
                  >
                    Palm Kernel oil extraction
                  </NavDropdown.Item>

                  {/* <NavDropdown.Item eventKey="4.9" as={Link} to="/Soyabeanoilextraction">
                    Soyab Bean oil extraction
                  </NavDropdown.Item> */}

                  <NavDropdown.Item
                    eventKey="4.10"
                    as={Link}
                    to="/Ricebraneoiltextraction"
                  >
                    Rice bran oil extraction
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    eventKey="4.11"
                    as={Link}
                    to="/Mustardoilextraction"
                  >
                    Mustard oil extraction
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item eventKey="4.12" as={Link} to="/Sunfloweroilextraction">
                    Sunflower oil extraction
                  </NavDropdown.Item> */}
                  {/* <NavDropdown.Item eventKey="4.13" as={Link} to="/Groundnutoilextraction">
                    Ground nut oil extraction
                  </NavDropdown.Item> */}

                  {/*  <NavDropdown.Item eventKey="4.14" as={Link} to="/Mangokarneloilextraction">
                    Mango Karnel oil extraction
                  </NavDropdown.Item> */}
                  {/* <NavDropdown.Item eventKey="4.15" as={Link} to="/ciaseedoilextraction">
                    Cia Seed oil extraction
                  </NavDropdown.Item> */}
                  {/* <NavDropdown.Item eventKey="4.16" as={Link} to="/salseedoilextraction">
                    Sal Seed oil extraction
                  </NavDropdown.Item> */}
                  {/*  <NavDropdown.Item eventKey="4.17" as={Link} to="/NeemSeedoilextraction">
                    Neem Seed oil extraction
                  </NavDropdown.Item> */}
                  {/* <NavDropdown.Item eventKey="4.18" as={Link} to="/Oilandarachneextraction">
                    Oil & Arachne extraction
                  </NavDropdown.Item> */}
                </NavDropdown>

                <NavDropdown
                  title="Edible oil"
                  id="basic-nav-dropdown"
                  className="dropend"
                >
                  <NavDropdown.Item
                    eventKey="5"
                    href="#action/3.2"
                  ></NavDropdown.Item>

                  <NavDropdown.Item eventKey="5.1" as={Link} to="/Refinery">
                    Refinery
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    eventKey="5.2"
                    as={Link}
                    to="/Fractionation"
                  >
                    Fractionation
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    eventKey="5.3"
                    as={Link}
                    to="/LayerEsterification"
                  >
                    Layer Esterification
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="5.4" as={Link} to="/CottonSeed">
                    Cotton Seed Delinting
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="5.5" as={Link} to="/OilMill">
                    Oil Mill
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    eventKey="5.6"
                    as={Link}
                    to="/SpentBleechingEarth"
                  >
                    Spent Bleeching Earth
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    eventKey="5.7"
                    as={Link}
                    to="/WaxPurification"
                  >
                    Wax Purification
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="5.8" as={Link} to="/FatSplitting">
                    Fat Splitting
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    eventKey="5.9"
                    as={Link}
                    to="/Hydrogenation"
                  >
                    Hydrogenation
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="5.10" as={Link} to="/PalmOilMill">
                    Palm Oil Mill
                  </NavDropdown.Item>

                  <NavDropdown.Item eventKey="5.11" as={Link} to="/Pome">
                    Oil Recovery From POME
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="5.12" as={Link} to="/Soap">
                    Soap
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Food Processing"
                  eventKey="6"
                  id="basic-nav-dropdown"
                  className="dropend"
                >
                  <NavDropdown.Item
                    eventKey="6.1"
                    href="#action/3.1"
                  ></NavDropdown.Item>
                  {/*  <NavDropdown.Item eventKey="6.2" as={Link} to="/FlourMill">
                    Flour Mill
                  </NavDropdown.Item> */}

                  <NavDropdown.Item
                    eventKey="6.3"
                    as={Link}
                    to="/BakeryShortening"
                  >
                    Bakery Shortening & Margarine
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title="Green Energy"
                  id="basic-nav-dropdown"
                  className="dropend"
                >
                  <NavDropdown.Item
                    eventKey="7"
                    href="#action/3.1"
                  ></NavDropdown.Item>
                  <NavDropdown.Item eventKey="7.1" as={Link} to="/SolarEnergy">
                    Solar Energy
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="7.2" as={Link} to="/Biodiesel">
                    Bio-Diesel
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Associated Projects"
                  id="basic-nav-dropdown"
                  className="dropend"
                >
                  <NavDropdown.Item
                    eventKey="8"
                    href="#action/3.1"
                  ></NavDropdown.Item>
                  {/* <NavDropdown.Item eventKey="8.1" as={Link} to="/Detergent">
                    Detergent Powder
                  </NavDropdown.Item> */}
                  {/*  <NavDropdown.Item eventKey="8.2" as={Link} to="/Toilet">
                    Toilet Soap
                  </NavDropdown.Item> */}
                  {/* <NavDropdown.Item eventKey="8.3" as={Link} to="/Potato">
                    Potato French Fries
                  </NavDropdown.Item> */}
                  <NavDropdown.Item eventKey="8.4" as={Link} to="/Tomato">
                    Tomato
                  </NavDropdown.Item>

                  {/* <NavDropdown.Item eventKey="8.5" href="#action/3.2">
                    Pharmaceutical
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="8.6" href="#action/3.2">
                    Breeding Small Farm
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="8.7" href="#action/3.2">
                    IV Fluid
                  </NavDropdown.Item> */}
                </NavDropdown>
              </NavDropdown>

              <Nav.Link eventKey="9" as={Link} to="/PatentandAwards">
                Patent & Awards
              </Nav.Link>

              <NavDropdown title="Services" id="basic-nav-dropdown">
                <NavDropdown.Item
                  eventKey="10"
                  href="#action/3.1"
                ></NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="10.1"
                  as={Link}
                  to="/DesignandEngineering"
                >
                  Design and Engineering
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="10.2" as={Link} to="/epcmepcmc">
                  EPCM,EPC,PMC
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="10.3" as={Link} to="/fabrication">
                  Fabrication Work
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="10.4" as={Link} to="/Maintainance">
                  Maintainance
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="11.6" as={Link} to="/PrivacyPolicy">
                  Privacy Policy
                </NavDropdown.Item>
              </NavDropdown>

              {/* <NavDropdown title="Sustainibility" id="basic-nav-dropdown">
                <NavDropdown.Item eventKey="11" href="#action/3.1"></NavDropdown.Item>
                <NavDropdown.Item eventKey="11.1" as={Link} to="/Sustainibility">
                  Quality Management
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="11.2" href="#action/3.2">
                  Green Energy for Envoroment
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="11.3" href="#action/3.2">
                  Corporate Social Responsiblity
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="11.4" href="#action/3.2">
                  Research and Development
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="11.5" href="#action/3.2">
                  Employees and Workplace
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="11.6" as={Link} to="/PrivacyPolicy">
                  Privacy Policy
                </NavDropdown.Item>

              </NavDropdown> */}

              <Nav.Link eventKey="12" as={Link} to="/contact">
                Contact
              </Nav.Link>
              <h5 className="download">Download</h5>
            <Nav.Link className="brochure" href={Brochure} download={Brochure}>
              Brochure
            </Nav.Link>
              <Form>
                {" "}
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  onClick={() => toggleTheme()}
                />{" "}
              </Form>
             
            </Nav>
           
          </Navbar.Collapse>
        </div>
      </Navbar>
    </router>
  );
};
export default BasicExample;
