import React, { useEffect, useRef, useState } from "react";

import { gsap, Expo } from "gsap";
import { Parallax, Background } from "react-parallax";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./About.css";
import abtvid from "./Assets/corporate Film.webm";
import abtvidIos from "./Assets/Few employees Low size.mp4";
import abtimg from "./images/Allseed.webp";
import ReactPlayer from 'react-player';
import VideoComponent from "./VideoComponent";
const About = () => {
  let Content = useRef();
  let abtVideo = useRef();
  const playButtonRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayPause = () => {
    const video = document.querySelector('.vid1');
    const playButton = document.querySelector('.play-button');
  
    if (video.paused) {
      video.play();
      playButton.style.display = 'none'; // Hide play button when video is playing
    } else {
      video.pause();
      playButton.style.display = 'block'; // Show play button when video is paused
    }
  };

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.registerPlugin(ScrollTrigger);
      const tl = gsap.timeline({});

      tl.from(
        Content.current,
        {
          scrollTrigger: {
            trigger: ".abtWrapper",
            start: "top, 80%",
            end: "60% bottom",

            scrub: 1,
          },
          xPercent: -100,
          duration: 2,
          opacity: 0,
          ease: Expo.easeIn,
        },
        "a"
      );
      tl.from(
        abtVideo.current,
        {
          scrollTrigger: {
            trigger: ".abtWrapper",
            start: "top top",
            end: "70% bottom",
            scrub: 1,
          },

          xPercent: 100,
          duration: 2,
          opacity: 0,
          ease: Expo.easeIn,
        },
        "a"
      );
    });

    return () => ctx.revert();
  }, []);

  return (
    <>
      <Parallax className="abtWrapper" strength={100}>
        <Background className="custom-bg">
          <img src={abtimg} alt="" loading="lazy" />
        </Background>
        <div className="abtusHeading">
          <h1>About US</h1>
        </div>

        <div className="aboutContainer panel">
          <div className="aboutContent" ref={Content}>
            <p className="abtP">
              Mecpro is a leading supplier of turnkey projects in the field of
              oils, fats, surfactants and oleochemicals. In its 38 years of
              operation, it has developed hundreds of new technological
              processes of which 17 have been granted patents. On the strength
              of its engineering capabilities, it has provided over 280 clients
              with plants that are long lasting, require low maintenance, are
              environment friendly, are easy to operate and economical to erect
              and run. Mecpro has always focused on research and innovation,
              which are the foundation of its services. Its strong belief in
              fairness, in serving the customer sincerely and in not cutting
              corners has enabled it to gain the trust of not only its clients
              but also its own vendors and its workforce. Mecpro’s work ethos
              and its values have resulted in numerous accolades, both for the
              company and for its founder. These honours have come from all over
              – Industry Associations, the Government, as well as International
              bodies.{" "}
            </p>
          </div>
          <div className="aboutImages">
      <div ref={abtVideo} className="video-container">
      <div className="vid1">
   <ReactPlayer
        url="https://www.youtube.com/watch?v=gMt4EopCMGM" // Your YouTube video URL
        controls={true} // Show default controls
        width="100%"
        height="100%"
        className="ytplayer"
      /> 
      
    </div>

      
      </div>
    </div>
        </div>
      </Parallax>
    </>
  );
};

export default About;
