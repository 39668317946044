import { Helmet } from "react-helmet";
import PalmFibreFaq from "./PalmFibreFaq";
import "./Palmfibreoilectraction.css";
import fibre from "../components/Assets/SOLVENTEXTRACTIONFULLHD.webm";
import fibreios from "../components/Assets/SOLVENTEXTRACTIONFULLHD.mp4";
import ReactPlayer from "react-player";
const Palmfibreoilectraction = () => {
  return (
    <>
      <Helmet>
        <title> Palm Fibre Solvent Extraction Plant</title>
        <meta
          name="discription"
          content="Palm fibre is a by-product
            after extraction of Oil from Palm
            Fruits. Due to limitation of mech-
            anical pressing 5-6% oil is left over
            in Mesocarp Fiber."
        />
        <meta
          name="keyword"
          content="Palm Fibre Oil Extraction, palm fibre, palm fibre oil, Mesocarp Fibre Solvent Extraction,  "
        />
      </Helmet>
      <div>
        <h1 className="Refinery">Palm Fibre -Solvent Extraction Plant</h1>
      </div>
      <div className="refinaryContainer">
        <div className="HimgContainer">
          <img
            className="HimgContainer"
            src="./Images/1-1801777410@2x.png"
            alt="PALM FIBRE"
          />
        </div>

        {/* <h1 className="pageHeading">MesoCarp Fibre</h1> */}

        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/mesocarofiber.png"
            alt="PALM FIBRE-1"
          />
          <h5 className="ref1h5">
            Mesocarp fibre is a by-product after extraction of Oil from Palm
            Fruits. Due to limitation of mech- anical pressing 5-6% oil is left
            over in Mesocarp Fiber.{" "}
          </h5>
        </section>

        <h5 className="mecpro1">
          In order to have a sustainable value chain in palm processing, MECPRO
          has developed a process technology to recover the residual
        </h5>
        <section className="refinery1">
          <h5 className="ref1h5">
            {" "}
            Oil in the wet Mesocarp Fibre, using flammable/non-flammable
            solvent, thus ensuring environmentally sound, economically viable
            and socially acceptable practice. The technology has been developed,
            patented & successfully commercialized. For the capital investment
            required, the project is viable with a short payback.{" "}
          </h5>
          <img
            className="refImg"
            src="./Images/2-181014@2x.png"
            alt="palm fibre-2"
          />
        </section>
        <h5 className="mecpro1">
          The Mesocarp fibre oil is rich source of Carotenoids, Vitamin E,
          Tocopherol and tocotrienols and Sterols. It contains significant
          quantities of Carotenoids (3000- 5000 ppm),Vitamin E (2400-3500 ppm)
          and Sterols (2000-4000 ppm). By adopting three patented technologies,
          the oil is separated below the oxidization temperature which enhances
          the quality of oil, carotenoids and other un-saponifiable matters.
        </h5>

        <section className="refVid">
            <ReactPlayer
                            url="https://www.youtube.com/watch?v=wGfCIo_3lUU" // Your YouTube video URL
                            controls={true} // Show default controls
                            width="100%"
                            height="100%"
                            className="ytplayer"
                          /> 
        </section>

        <h5 className="mecpro1">
          The process developed by MECPRO is unique as it does not require the
          fibre to be dried and wet slivered fibre can be directly processed.
          This technology has enhanced the oil extraction rate & maintained a
          better quality of the oil. For the capital investment required, the
          project is viable with payback within 1 year for 120 Ton FFB/Hr. going
          on to 3 years for 45 Ton FFB/Hr.
        </h5>
      </div>
      <h1 className="faqHeading">Frequently Asked Questions!</h1>

      <div className="faqContainer">
        <PalmFibreFaq />
        {/* <div className="faqImages">
          <img src="./Images/SEP-37.JPG" alt="palm fibre-3" />
          <img src="./Images/DSC02377.JPG" alt="palm fibre-4" /> */}
        {/* <img src="./Images/DSC02397.JPG" alt="" /> */}
        {/* <img src="./Images/20180828_085337.jpg" alt="" /> */}
        {/* <img src="./Images/20180828_091212.jpg" alt="" /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default Palmfibreoilectraction;
