import React, { useState, useEffect, useCallback } from 'react';
import './LanguageSelector.css';
import { 
  Box,
  Modal,
  Typography,
  TextField,
  Paper,
  Grid,
  IconButton,
  Chip,
  Fade,
  Container,
  Card,
  CardContent,
  InputAdornment,
  Divider,
  CircularProgress,
  Fab,
  Tooltip,
  Snackbar,
  Button
} from '@mui/material';
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  Refresh as RefreshIcon,
  Translate as TranslateIcon,
  ArrowForward as ArrowForwardIcon,
  History as HistoryIcon,
  Close as CloseIcon
} from '@mui/icons-material';

const LanguageSelector = () => {
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [recentLanguages, setRecentLanguages] = useState([]);
  const [isTranslating, setIsTranslating] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [initAttempts, setInitAttempts] = useState("");
  const [error, setError] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);

  // Comprehensive list of Google Translate languages
  const languages = {
    en: 'English',
    af: 'Afrikaans',
    sq: 'Albanian',
    am: 'Amharic',
    ar: 'Arabic',
    hy: 'Armenian',
    az: 'Azerbaijani',
    eu: 'Basque',
    be: 'Belarusian',
    bn: 'Bengali',
    bs: 'Bosnian',
    bg: 'Bulgarian',
    ca: 'Catalan',
    ceb: 'Cebuano',
    zh: 'Chinese',
    co: 'Corsican',
    hr: 'Croatian',
    cs: 'Czech',
    da: 'Danish',
    nl: 'Dutch',
    eo: 'Esperanto',
    et: 'Estonian',
    fi: 'Finnish',
    fr: 'French',
    fy: 'Frisian',
    gl: 'Galician',
    ka: 'Georgian',
    de: 'German',
    el: 'Greek',
    gu: 'Gujarati',
    ht: 'Haitian Creole',
    ha: 'Hausa',
    haw: 'Hawaiian',
    he: 'Hebrew',
    hi: 'Hindi',
    hmn: 'Hmong',
    hu: 'Hungarian',
    is: 'Icelandic',
    ig: 'Igbo',
    id: 'Indonesian',
    ga: 'Irish',
    it: 'Italian',
    ja: 'Japanese',
    jv: 'Javanese',
    kn: 'Kannada',
    kk: 'Kazakh',
    km: 'Khmer',
    ko: 'Korean',
    ku: 'Kurdish',
    ky: 'Kyrgyz',
    lo: 'Lao',
    la: 'Latin',
    lv: 'Latvian',
    lt: 'Lithuanian',
    lb: 'Luxembourgish',
    mk: 'Macedonian',
    mg: 'Malagasy',
    ms: 'Malay',
    ml: 'Malayalam',
    mt: 'Maltese',
    mi: 'Maori',
    mr: 'Marathi',
    mn: 'Mongolian',
    my: 'Myanmar (Burmese)',
    ne: 'Nepali',
    no: 'Norwegian',
    ny: 'Nyanja (Chichewa)',
    or: 'Odia (Oriya)',
    ps: 'Pashto',
    fa: 'Persian',
    pl: 'Polish',
    pt: 'Portuguese',
    pa: 'Punjabi',
    ro: 'Romanian',
    ru: 'Russian',
    sm: 'Samoan',
    gd: 'Scots Gaelic',
    sr: 'Serbian',
    st: 'Sesotho',
    sn: 'Shona',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'Slovak',
    sl: 'Slovenian',
    so: 'Somali',
    es: 'Spanish',
    su: 'Sundanese',
    sw: 'Swahili',
    sv: 'Swedish',
    tg: 'Tajik',
    ta: 'Tamil',
    tt: 'Tatar',
    te: 'Telugu',
    th: 'Thai',
    tr: 'Turkish',
    tk: 'Turkmen',
    uk: 'Ukrainian',
    ur: 'Urdu',
    ug: 'Uyghur',
    uz: 'Uzbek',
    vi: 'Vietnamese',
    cy: 'Welsh',
    xh: 'Xhosa',
    yi: 'Yiddish',
    yo: 'Yoruba',
    zu: 'Zulu'
  };
  useEffect(() => {
    if (document.readyState === 'complete') {
      setPageLoaded(true);
    } else {
      window.addEventListener('load', () => {
        setPageLoaded(true);
      });
    }
  }, []);

  // Show prompt after 5 seconds of page load
  useEffect(() => {
    if (pageLoaded) {
      const timer = setTimeout(() => {
        setShowPrompt(true);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [pageLoaded]);
  
  // Debug logging function
  const debugLog = (stage, message, data = null) => {
    const timestamp = new Date().toISOString();
    console.log(`[${timestamp}] [${stage}]:`, message, data ? data : '');
  };

  const loadTranslateScript = useCallback(() => {
    if (scriptLoaded) {
      debugLog('SCRIPT_LOAD', 'Script already loaded, skipping');
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      const existingScript = document.querySelector('script[src*="translate_a/element.js"]');
      if (existingScript) {
        setScriptLoaded(true);
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit&hl=en';
      script.async = true;

      script.onload = () => {
        debugLog('SCRIPT_LOAD', 'Script loaded successfully');
        setScriptLoaded(true);
        resolve();
      };

      script.onerror = (error) => {
        debugLog('SCRIPT_LOAD_ERROR', 'Error loading script', error);
        reject(error);
      };

      document.body.appendChild(script);
    });
  }, [scriptLoaded]);

  useEffect(() => {
    loadTranslateScript();

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement({
        pageLanguage: 'en',
        includedLanguages: Object.keys(languages).join(','),
        layout: window.google.translate.TranslateElement.InlineLayout.VERTICAL,
        autoDisplay: false,
      }, 'google_translate_element');
      debugLog('GOOGLE_INIT', 'Google Translate element initialized');
    };
   
  }, [loadTranslateScript]);

  const handlePromptAction = (action) => {
    if (action === 'change') {
      setShowModal(true);
    }
    setShowPrompt(false);
  };


  const setLanguageDirectly = useCallback(() => {
    if (!selectedLanguage) return;

    try {
      const selectElement = document.querySelector('select.goog-te-combo');
      if (selectElement) {
        debugLog('SELECT_FOUND', 'Found select element, setting value directly');
        selectElement.value = selectedLanguage;
        selectElement.dispatchEvent(new Event('change'));
        setIsTranslating(false);
        return;
      }
    } catch (error) {
      debugLog('ERROR', error.message);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (scriptLoaded && selectedLanguage && selectedLanguage !== 'en') {
      debugLog('SETTING_LANGUAGE', `Attempting to set language: ${selectedLanguage}`);
      setLanguageDirectly();
    }
  }, [scriptLoaded, selectedLanguage, setLanguageDirectly]);

  const initializeTranslation = (languageCode) => {
    if (languageCode === 'en') {
      debugLog('INIT_TRANSLATION', 'English selected, no translation needed');
      setShowModal(false);
      return;
    }
    debugLog('INIT_TRANSLATION', `Initializing translation for language: ${languageCode}`);
    setIsTranslating(true);
    setShowModal(false);
    setSelectedLanguage(languageCode);
    
    // Add to recent languages if not already present
    setRecentLanguages(prev => {
      const updatedRecent = [languageCode, ...prev.filter(code => code !== languageCode)].slice(0, 5);
      return updatedRecent;
    });
  };

  const filteredLanguages = Object.entries(languages).filter(([code, name]) =>
    name.toLowerCase().includes(searchTerm.toLowerCase())
  );
 
 
  
  return (
    <>
        <Snackbar
        open={showPrompt}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ bottom: { xs: '24px', sm: '24px' } }}
      >
        <Paper
          elevation={6}
          sx={{
            p: 2,
            bgcolor: 'background.paper',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <TranslateIcon color="primary" />
          <Typography variant="body1" sx={{color:'#000'}}>
            Would you like to change the language?
          </Typography>
          <Button 
            size="small" 
            color="primary" 
            onClick={() => handlePromptAction('change')}
          >
            Change
          </Button>
          <IconButton 
            size="small" 
            onClick={() => handlePromptAction('close')}
            sx={{ ml: 1 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Paper>
      </Snackbar>
        <Tooltip title="Change Language">
        <Fab
          color="primary"
          aria-label="change language"
          onClick={() => setShowModal(true)}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000,
          }}
        >
          <TranslateIcon />
        </Fab>
      </Tooltip>
       <Modal
        open={showModal}
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Fade in={showModal}>
          <Container maxWidth="md">
            <Paper
              elevation={24}
              sx={{
                p: 4,
                maxHeight: '90vh',
                overflowY: 'auto',
                borderRadius: 3,
                bgcolor: 'background.paper',
                position: 'relative',
              }}
            >
               <IconButton
                onClick={() => setShowModal(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'text.secondary',
                  '&:hover': {
                    color: 'text.primary',
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
              {/* Header */}
              <Box sx={{ textAlign: 'center', mb: 4 }}>
                <TranslateIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
                  Choose Your Language
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Select your preferred language to continue
                </Typography>
              </Box>

              {/* Search */}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search languages..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 3 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchTerm && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchTerm('')} edge="end" size="small">
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* Recent Languages */}
              {recentLanguages.length > 0 && (
                <Box sx={{ mb: 3 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    <HistoryIcon fontSize="small" color="action" />
                    <Typography variant="subtitle2" color="text.secondary">
                      Recent
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    {recentLanguages.map(code => (
                      <Chip
                        key={`recent-${code}`}
                        label={languages[code]}
                        onClick={() => initializeTranslation(code)}
                        color="primary"
                        variant="outlined"
                        icon={<TranslateIcon />}
                        clickable
                      />
                    ))}
                  </Box>
                </Box>
              )}

              <Divider sx={{ my: 3 }} />

              {/* Language Grid */}
              <Grid container spacing={2}>
                {filteredLanguages.map(([code, name]) => (
                  <Grid item xs={12} sm={6} md={4} key={code}>
                    <Card
                      variant="outlined"
                      sx={{
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: 2,
                          borderColor: 'primary.main',
                        },
                      }}
                      onClick={() => initializeTranslation(code, name)}
                    >
                      <CardContent sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '&:last-child': { pb: 2 }
                      }}>
                        <Box>
                          <Typography variant="subtitle1" component="div">
                            {name}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {code.toUpperCase()}
                          </Typography>
                        </Box>
                        <ArrowForwardIcon color="action" sx={{ opacity: 0.5 }} />
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Container>
        </Fade>
      </Modal>

      {/* Translation Widget Container */}
     

<Box
  sx={{
    position: 'fixed',
    bottom: 16,
    right: 16,
    zIndex: 1000,
  }}
>
  {isTranslating && (
    <Paper
      sx={{
        mb: 2,
        p: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <CircularProgress size={20} />
      <Typography variant="body2">
        Translating content... (Attempt {initAttempts})
      </Typography>
    </Paper>
  )}
  <Paper
    elevation={3}
    sx={{ p: 2 }}
  >
    <div id="google_translate_element"></div>
    <IconButton
      size="small"
      onClick={() => {
        debugLog('REFRESH_CLICK', 'Manual refresh triggered');
        window.location.reload();
      }}
      sx={{ mt: 1 }}
      color="primary"
    >
      <RefreshIcon />
    </IconButton>
  </Paper>
</Box>
</>
);
};

export default LanguageSelector;
