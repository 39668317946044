import { Helmet } from "react-helmet";
import "./LayerEsterification.css";
import LayerEsterificationfaq from "./LayerEsterificationfaq";
import layer from "../components/Assets/LAYER ESTERIFICATION FULL HD.mp4";
import ReactPlayer from "react-player";
const LAYERESTERIFICATION = () => {
  return (
    <>
      <Helmet>
        <title>Layer Esterification Plant </title>
        <meta
          name="description"
          content="Layer esterification is a process
            similar to glycerolizes process,
            but layer esterification does not
            require any catalyst or any other
            ingredient for rebonding the gly-
            cerol back into the free fatty acid
            molecules."
        />
        <meta
          name="keywords"
          content=" Layer Esterification, Glycerolizes, Esterification Plant, glycerolysis Plant, Fatty Acid Esterification Plant"
        />
      </Helmet>
      <div>
        <h1 className="Refinery">LAYER ESTERIFICATION</h1>
      </div>
      <div className="refinaryContainer">
        <div className="HimgContainer">
          <img
            className="RefinieryHeaderImage"
            src="./Images/20181002_141209.webp"
            alt="Layer-esterification"
          />
        </div>
        {/* <h1 className="pageHeading">LAYER ESTERIFICATION</h1> */}
        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/header-1@2x.png"
            alt="Layer-esterification-1"
          />
          <article>
            Layer esterification is a process similar to glycerolizes process,
            but layer esterification does not require any catalyst or any other
            ingredient for rebonding the gly- cerol back into the free fatty
            acid molecules. It will be done in a layer form where the water
            formed in the reaction will be separated without effecting other
            glycerides.{" "}
          </article>
        </section>
        <h5 className="mecpro1">
          For example, oil with 5 - 15% FFA or 100% FFA can be converted online
          into glycerides in the refining process itself. This system can even
          be incorporated in the existing refinery without disturbing the
          existing refining process.
        </h5>
        <section className="refinery1">
          <article>
            10-12% glycerin is required for re-bonding process in case of oleic
            free fatty acid & 11-13% for palmetic free fatty acid quantity in
            the feed stock. No additional utilities are required for the project
            in the refinery. 60% of the Free Fatty Acid in the oil will convert
            as triglyceride & 40% as diglyceride. In case of chemical refining,
            the system can be by passed to run the refining part only. Any
            traces of fatty acid in the layer esterification are continuously
            re-fed to the layer esterifier and this will ensure that there will
            not be any free fatty acid produced in the process.
          </article>
          <img
            className="refImg"
            src="./Images/20180509_173727.png"
            alt="glycerolizes"
          />
        </section>
        <section className="refVid">
         <ReactPlayer
                          url="https://www.youtube.com/watch?v=KluJogE_36E" // Your YouTube video URL
                          controls={true} // Show default controls
                          width="100%"
                          height="100%"
                          className="ytplayer"
                        /> 
        </section>
        <br />
        <h5 className="mecpro1">
          For example, oil with 5 - 15% FFA or 100% FFA can be converted online
          into glycerides in the refining process itself. This system can even
          be incorporated in the existing refinery without disturbing the
          existing refining process.
        </h5>
        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/20181004_182816.png"
            alt="glycerolizes-2"
          />
          <div className="layerh5">
            <h1 className="Promp">Promptness</h1>
            <article>
              We respect and realize the value of time and to deliver with
              promptness is our distinguished hallmark.
            </article>
          </div>
        </section>
        <h5 className="mecpro1">
          Resultant, de-oiled Spent Bleaching Earth is safe enough to be
          disposed in landfills. The anhydrous clay can be used in cement
          manufacturing, as well. It can also be used in manufacturing
          fertilizers containing silica/silicates, or can be used as a soil
          amendment, as it poses no harm to the soil and acts as a soil
          conditioner.
        </h5>{" "}
        <br />
      </div>
      <h1 className="faqHeading">Frequently Asked Questions!</h1>
      <div className="faqContainer">
        <LayerEsterificationfaq />
        <div className="faqImages">
          <img src="./Images/20180323_125924.jpg" alt="glycerolizes-2" />
          <img src="./Images/20180828_090759.jpg" alt="glycerolizes-3" />
          <img
            src="./Images/20180828_090109.jpg"
            alt="Layer-esterification-2"
          />
          <img
            src="./Images/20180828_085337.jpg"
            alt="Layer-esterification-3"
          />
          {/* <img src="./Images/20180828_091212.jpg" alt="" /> */}
        </div>
      </div>
    </>
  );
};

export default LAYERESTERIFICATION;
