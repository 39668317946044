import "./Fractionation.css";
import "./Ricebraneoiltextraction.css";
import vid from "../components/Assets/Rice Bran.webm";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";


const Ricebraneoiltextraction = () => {
  return (
    <>
      <Helmet>
        <title>Rice bran oil extraction </title>
        <meta name="description" content="Ricebrane oil extraction" />
        <meta name="keywords" content=" Ricebrane oil extraction" />
      </Helmet>

      {/* <section className="refinery1">
        <img className="refImg" src="./Images/rice2.jpg" alt="Refinery-img1" />
        <h5 className="ref1h5">
          Rice bran comes from the outer layer of the rice,It is popular as a health oil in most asia <br />
          Rice bran oil contains substance that might decrease how much chalastrol the body absorbs <br />
          Rice bran is generally bieng used to generate steam and electricity 12%-36% oil in the rice bran composition of rice bran <br />
          Rice bran is the outer layer of rice
          OIL           		: 12% TO 36%
          MOISTURE		: 8% TO 9%</h5>

      </section>









      <section className="refinery1">

        <h5 className="ref1h5">Rice Bran Technology<br />
          New way to process rice bran solvent extraction <br />
          No need to make pellets <br />
          Preperatory section removed and cost reduction implemented <br />
          With more bleechability the extraction rate will be higher  <br />
          Utility consumption will be reduced <br />
          Manpower will be reduced<br />
          In turn of capital cost will reduce to about 30%<br />
          Maintenance and inventery cost will be reduced subtansially</h5>
        <div className="reffImg2">
          <img className="reffImg2" src="./Images/rice.png" alt="Refinery-img1" />
        </div>

      </section>



      <h1 className="pageHeading2">CONCLUSION <br />
      </h1>
      <h5 className="mecpro1">New opertunity for accelerated growth <br />
        Low cost, Efficient & Effective technology <br />
        To bring new concept into focus <br />
        Best quality at best prize <br />

      </h5> */}

      <section className="refVid">
         <ReactPlayer
                         url="https://www.youtube.com/watch?v=FTpD5AO-V6c" // Your YouTube video URL
                         controls={true} // Show default controls
                         width="100%"
                         height="100%"
                         className="ytplayer"
                       /> 
      </section>
    </>
  );
};
export default Ricebraneoiltextraction;
